import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import logo from '../images/logo.jpg';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        color: '#3743AA',
        margin: 'auto',
        textAlign: 'center'
    },
    image: {
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%'
    }
});

const Error = (props) => {
    const classes = useStyles();
    return(
        <Material.Card>
            <Material.CardHeader></Material.CardHeader>
            <Material.CardMedia
                //src={logo}
                children={
                    <img src={logo} className={classes.image}/>
                }
                title={props.titleImage}
            />
            <Material.CardContent>
                <h1 className={classes.title}>Error</h1>
                <br/>
                <br/>
                <br/>
                <p>
                     Hay un problema con la conexión{/*props.message*/}
                <br/>
                     Intenta en unos minutos.
                </p>
            </Material.CardContent>
        </Material.Card>
    );
}

export default Error;