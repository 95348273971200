import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as Components from '../components';
import * as HTTP from '../http/http';
//import logo from '../images/logo.jpg';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import right from '../icons/chevron-right.svg';
import ReactGA from 'react-ga'

const Taxonomy = (props) => {
    let ua = 'UA-150925311-1';
    if(process.env.REACT_APP_ENV == 'pro'){
        ua = 'UA-159643108-1';
    }
    ReactGA.initialize(ua);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const query = new URLSearchParams(window.location.search);

    const [load, setLoad] = useState(false);
    const [update, setUpdate] = useState(false);
    const [listTickets, setListTickets] = useState([]);
    //const [categories, setCategories] = useState(props.categories);
    const [title, setTitle] = useState('Centro de ayuda');
    const [selected, setSelected] = useState(false);
    
    useEffect(() => {
        const getListTickets = async (uuid) => {
            setLoad(true);
            let list = await HTTP.getTickets(uuid);
            if(list.status == 200){
                if(list.data.data.hasOwnProperty('tickets')){
                    setListTickets(list.data.data.tickets);
                    props.updateListTicket(list.data.data.tickets);
                }
            }
            setLoad(false);
        }

        const getListTicketsAsync = async (uuid) => {
            let list = await HTTP.getTickets(uuid);
            if(list.status == 200){
                if(list.data.data.hasOwnProperty('tickets')){
                    setListTickets(list.data.data.tickets);
                    props.updateListTicket(list.data.data.tickets);
                }
            }
        }
        
        if(props.nav.length == 0){
            getListTickets(props.queryParams.uuid);
        }else{
            getListTicketsAsync(props.queryParams.uuid);
        }

    }, []);

    const selectCategory = (item, key) => {
        ReactGA.event({
            category: `CategorySelected`,
            action: `User ${props.queryParams.uuid}`,
            label: item.name
        })
        props.changeNav('next', key);
        if(!item.hasOwnProperty('categories')){
            props.changeNavPage('next', 'article');
        }
    }
    
    return(
        <div>
            <Material.CssBaseline/>
        <Material.CardContent style={{width: '100%'}}>
            {
                load && 
                <Components.Loader/>
            }
            {
                !load &&
                <div>
                    {
                        <div>
                            { 
                                props.item.name &&
                                <div className={'size-12 settings'}>
                                    <b>
                                    {
                                        props.item.name ? props.item.name : ''
                                    }
                                    </b>
                                </div>
                            }
                            {
                                props.nav && props.nav.length == 0 &&
                                <div className={'size-12 settings white_background'}>
                                    <h3>¿Cómo podemos ayudarte?</h3>
                                </div>
                            }
                            {
                                props.categories && props.categories.map((item, key) => {
                                    if(item.active){
                                        return <div key={key} className={'size-12 settings'} onClick={selectCategory.bind(this, item, key)}>
                                            <span>{item.name}</span>
                                            <img src={right} alt=""/>
                                        </div>

                                    }
                                })
                            }
                        </div>
                    }
                </div>
            }
            {
                !load && listTickets.length > 0 && props.nav && props.nav.length == 0 && !load &&
                <div className={'size-12 bigButton'} style={{marginTop: '16px'}}>
                    <button onClick={props.changeNavPage.bind(this, 'next', 'listTicket')}>
                        Mis tickets
                    </button>
                </div>
            }
        </Material.CardContent>
        </div>
    );
}

export default Taxonomy;