import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import logo from '../images/dribbble_shot.png';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        color: '#3743AA',
        margin: 'auto',
        textAlign: 'center'
    },
    image: {
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%'
    }
});

const Thanks = (props) => {
    const classes = useStyles();
    return(
        <Material.Card>
            <Material.CardHeader></Material.CardHeader>
            <Material.CardMedia
                //src={logo}
                children={
                    <img src={logo} className={classes.image}/>
                }
                title={props.titleImage}
            />
            <Material.CardContent>
                <h1 className={classes.title}>Tendrás una respuesta en menos de 15 minutos</h1> 
                <br/>
                <br/>
                <br/>
                <p>
                    Gracias por contactarnos, ¡tú eres lo más importante para nosotros!
                
                </p>
                 
                   
                
            </Material.CardContent>
        </Material.Card>
    );
}

export default Thanks;