import React from 'react';
import * as Material from '@material-ui/core';
import spinner from '../images/spinner.svg';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

const Loader = () => {
    const classes = useStyles();
    return(
        <Material.Backdrop open={true} className={classes.backdrop}>
            <Material.CircularProgress/>
        </Material.Backdrop>
    );
}

export default Loader;