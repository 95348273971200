
import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as HTTP from '../http/http';
import * as Components from '../components';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
    content: {
        margin: '0px', 
        textAlign: 'center'
    },
    justify: {
        textAlign: 'justify'
    },
    center: {
        margin: 'auto'
    },
    contentFeedback: {
        textAlign: 'center',
        marginBottom: '18px'
    },
    buttonSend: {
        marginTop: '24px'
    },
    contentThanks: {
        marginBottom: '32px'
    }
});

const Article = (props) => {
    let ua = 'UA-150925311-1';
    if(process.env.REACT_APP_ENV == 'pro'){
        ua = 'UA-159643108-1';
    }
    ReactGA.initialize(ua);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const classes = useStyles();
    const [article, setArticle] = useState({});
    const [load, setLoad] = useState(true);
    const [rate, setRate] = useState(false);
    const [valueRate, setValueRate] = useState(false);
    const [sendValue, setSendValue] = useState(false);
    const [valueRadio, setValueRadio] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [exit, setExit] = useState(false);

    useEffect(() => {
        const getArticle = async (uuid, customer) => {
            setLoad(true);
            let art = await HTTP.getArticle(uuid, customer);
            if(art.status == 200){
                if(art.data.data.hasOwnProperty('uuid')){
                    setArticle(art.data.data);
                }
            }
            setLoad(false);
        }

        getArticle(props.item.uuid, props.queryParams);
    }, []);

    const rateArticle = async (value) => {
        if(value){
            ReactGA.event({
                category: 'ArticleRate',
                action: `User Article Rate: ${props.queryParams.uuid}`,
                label: `${value.toString()} ${props.item.name}`
            });
            setLoad(true);
            let feedback = await HTTP.feedbackArticle(props.queryParams.uuid, props.item.uuid, value, null);
            setExit(true);
            setLoad(false);
        }else{
            ReactGA.event({
                category: 'ArticleRate',
                action: `User Article Rate: ${props.queryParams.uuid}`,
                label: `false ${props.item.name}`
            });
        }
        setRate(true);
        setValueRate(value);
    }

    const changeValue = (value) => {
        setValueRadio(value);
        if(value){
            setDisabled(false);
        }
    }

    const sendValueFunc = async () => {
        setLoad(true);

        ReactGA.event({
            category: 'ArticleFeedback',
            action: `User Article Feedback: ${props.queryParams.uuid}`,
            label: `${valueRadio} ${props.item.name}`
        });
        
        let feedback = await HTTP.feedbackArticle(props.queryParams.uuid, props.item.uuid, false, valueRadio);
        setExit(true);
        setLoad(false);
        setSendValue(true);
    }

    const goHome = () => {
        setLoad(true);
        window.location.reload();
    }


    return (
        <div>
            {
                !load &&
                <div className={'white_background'}>
                    <h2>{article['name']}</h2>
                    <p className={classes.justify}>{ReactHtmlParser(article['article'])}</p>
                    
                    {
                        !rate &&
                        <div className={classes.content+' snow_background'}>
                            <p><b>¿Fue útil esta información?</b></p>
                            <div className={'size-12 tweenButton snow_background'}>
                                <button className={'secundary'} onClick={rateArticle.bind(this, false)}>
                                    <span>No</span>
                                </button>
                                <button className={'secundary'} onClick={rateArticle.bind(this, true)}>
                                    <span>Sí</span>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        rate && valueRate &&
                        <p className={`${classes.content} snow_background`}><b>¡Gracias!</b></p>
                    }
                    {
                        rate && !valueRate &&
                        <div className={classes.content}>
                            {
                                !sendValue &&
                                <div className={'size-12 radioButton snow_background'}>

                                    <div>

                                        <p className={classes.contentFeedback}><b>¿Qué salió mal?</b></p>
                                        <p onClick={changeValue.bind(this, 'No es lo que necesito')}>
                                            <input type="radio" id="test1" name="necesito" value="No es lo que necesito" checked={valueRadio == 'No es lo que necesito'} style={{zIndex: 99999}}/>
                                            <label>No es lo que necesito</label>
                                        </p>

                                        <p onClick={changeValue.bind(this, 'La información no es clara')}>
                                            <input type="radio" id="test2" name="clara" value="La información no es clara" checked={valueRadio == 'La información no es clara'} style={{zIndex: 99999}}/>
                                            <label>La información no es clara</label>
                                        </p>

                                        {/*
                                        <p onClick={changeValue.bind(this, 'La información no es clara')}>
                                            <input type="radio" id="test2" name="clara" value="La información no es clara" checked={valueRadio == 'La información no es clara'} style={{zIndex: 99999}}/>
                                            <label>La información no es clara</label>
                                        </p>
                                        <p onClick={changeValue.bind(this, 'Falta información')}>
                                            <input type="radio" id="test1" name="falta" value="Falta información" checked={valueRadio == 'Falta información'} style={{zIndex: 99999}}/>
                                            <label>Falta información</label>
                                        </p>
                                        <p onClick={changeValue.bind(this, 'No es la información que necesito')}>
                                            <input type="radio" id="test3" name="necesito" value="No es la información que necesito" checked={valueRadio == 'No es la información que necesito'} style={{zIndex: 99999}}/>
                                            <label>No es la información que necesito</label>
                                        </p>*/
                                        }
                                    </div>


                                </div>
                            }
                                            {
                                                !sendValue && disabled &&
                                                <div className={'size-12 bigButton disable snow_background '+ classes.buttonSend}>
                                                    <button disabled={disabled}>
                                                        Enviar
                                                    </button>
                                                </div>
                                            }
                                            {
                                                !sendValue && !disabled &&
                                                <div className={'size-12 bigButton secundary snow_background '+ classes.buttonSend}>
                                                    <button disabled={disabled} onClick={sendValueFunc.bind(this)}>
                                                        Enviar
                                                    </button>
                                                </div>
                                            }
                            {
                                sendValue && 
                                <div className={classes.contentThanks+ 'size-12 snow_background'}>
                                    <b>¡Muchas gracias por tu ayuda!</b>
                                </div>
                            }
                        </div>
                    }

                    {
                        exit &&
                        <div className={'size-12 bigButton secundary snow_background'}>
                            <button onClick={goHome.bind(this)}>
                                Volver al inicio
                            </button>
                        </div>
                    }

                    <div className={'size-12 bigButton snow_background'}>
                        <button onClick={props.changeNavPage.bind(this, 'next', 'ticketForm')}>
                            Necesito más ayuda
                        </button>
                    </div>
                </div>

            }
            {
                load &&
                <Components.Loader/>
            }
        </div>

    )
}

export default Article;  