import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import logo from '../images/UI-logo.svg';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        //fontFamily: 'montserrat',
        fontSize: '20px'
    }
});

const Header = (props) => {
    const query = new URLSearchParams(window.location.search);
    const classes = useStyles();

    const back = () => {
        if(query.has('ticket_uuid')){
            query.delete('ticket_uuid');
            const protocol = window.location.protocol;
            const host = window.location.host;
            const redirect = `${protocol}//${host}?${query.toString()}`;
            window.location.href = redirect;
        }
        if(props.pageNav.length == 0){
            props.changeNav('back', null);
            console.log('entro');
        }else if(props.pageNav.length == 1){
            props.changeNav('back', null)
            props.changeNavPage('back', '');
        }else{
            props.changeNavPage('back', '');
        }
    }
    return(
        <Material.CardHeader className={'white_background'}
            title={
                <h1 className={`${classes.title}`}>
                    {/*<div className={'size-12 text-right white_background'}>
                        <img src={logo} alt=""/>
            </div>*/}
                    {props.title}
                </h1>
            }
            avatar={
                <div>
                    {
                        props.icon == 'back' && 
                        <Material.IconButton onClick={back.bind(this)}>
                            <Icons.ChevronLeft/>
                        </Material.IconButton>
                    }
                </div>
            }
        />
    );
}

export default Header;