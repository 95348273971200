import React, {useState, useEffect} from 'react';
import * as Material from '@material-ui/core';
import logo from '../images/logo.jpg';
import es from 'moment/locale/es';
import moment from 'moment';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        color: '#3743AA',
        margin: 'auto',
        textAlign: 'center',
        fontSize: '14px'
    },
    image: {
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%'
    },
    text: {
        fontSize: '14px',
        textAlign: 'center'
    }
});


const ListTicket = (props) => {
    moment.locale('es');
    const classes = useStyles();
    const [ticketsClose, setTicketClose] = useState([]);
    const [ticketsOpen, setTicketOpen] = useState([]);
    useEffect(() => {
        let close = [];
        let open = [];
        for(let i = 0; i<props.listTicket.length; i++){
            if(props.listTicket[i].status == 'open' || props.listTicket[i].status == 'resolving'){
                open.push(props.listTicket[i]);
            }

            if(props.listTicket[i].status == 'resolve' || props.listTicket[i].status == 'solved'){
                close.push(props.listTicket[i]);
            }
        }

        setTicketOpen(JSON.parse(JSON.stringify(open)));
        setTicketClose(JSON.parse(JSON.stringify(close)));
    }, []);

    const goTicketDetail = (uuid) => {
        props.updateTicketUuid(uuid);
        props.changeNavPage('next', 'ticket');
    }
    return(
        <div>
            {
                ticketsOpen.length > 0 &&
                <div className={'size-12 transactionTitle'}>
                    Tickets en proceso
                </div>
            }
            {
                ticketsOpen.map((item, key) => {
                    return <div className={'size-12 cardIssue snow_background'} key={key} onClick={goTicketDetail.bind(this, item.ticket_uuid)}>
                        <div className={'card'}>
                            <h3><p>{item.title}</p></h3>
                            <div className={'flex justify-between align-center'}>
                                <span>{moment(item.cdate).locale('es').format('LL')} </span>
                                {
                                    item.status == 'open' &&
                                    <span className={'badge bolt_background white'}>En proceso</span>
                                }
                                {
                                    item.status == 'resolving' &&
                                    <span className={'badge bolt_background white'}>En proceso</span>
                                }
                                {
                                    item.status == 'resolve' &&
                                    <span className={'badge vivere_background white'}>Resuelto</span>
                                }
                            </div>
                        </div>
                    </div>
                })
            }
            <br/>
            <div className="snow_background"></div>
            {
                ticketsClose.length > 0 &&
                <div className={'size-12 transactionTitle'}>
                    Tickets resueltos
                </div>
            }
            {
                ticketsClose.map((item, key) => {
                    return <div className={'size-12 cardIssue snow_background'} key={key} onClick={goTicketDetail.bind(this, item.ticket_uuid)}>
                        <div className={'card'}>
                            <h3><p>{item.title}</p></h3>
                            <div className={'flex justify-between align-center'}>
                                <span>{moment(item.cdate).locale('es').format('LL')} </span>
                                {
                                    item.status == 'open' &&
                                    <span className={'badge bolt_background white'}>En proceso</span>
                                }
                                {
                                    item.status == 'resolving' &&
                                    <span className={'badge bolt_background white'}>En proceso</span>
                                }
                                {
                                    item.status == 'resolve' &&
                                    <span className={'badge vivere_background white'}>Resuelto</span>
                                }
                                {
                                    item.status == 'solved' &&
                                    <span className={'badge vivere_background white'}>Resuelto</span>
                                }
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    );
}

export default ListTicket;