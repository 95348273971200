import axios from 'axios';
let baseUrl = 'https://gateway-support-service.dev-albo.tech/customer_service/service';
if(process.env.REACT_APP_ENV == 'pro'){
    //baseUrl = 'https://support.albo.live/customer_service/service';
    baseUrl = 'https://support.albo.live/customer_service/service';
}

export const getTaxonomy = async () => {
    let url = baseUrl.replace('{service}', 'taxonomyget');
    let taxonomy = await axios({
        url: `${url}/TaxonomyGet@1.0`,
        method: 'POST',
        data: {
            data: {}
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return taxonomy;
}

export const getArticle = async (uuidCategory, userInfo) => {
    let url = baseUrl.replace('{service}', 'articleget');
    let article = await axios({
        url: `${url}/ArticleGet@1.0`,
        method: 'POST',
        data: {
            data: {
                uuidCategory: uuidCategory,
                customer: userInfo 
            }
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return article;
}

export const sendTicket = async (data) => {
    let url = baseUrl.replace('{service}', 'ticketcreate');
    let ticket = await axios({
        url: `${url}/TicketCreate@1.0`,
        method: 'POST',
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return ticket;
}

export const getTickets = async (uuid) => {
    let url = baseUrl.replace('{service}', 'ticketlist');
    let list = await axios({
        url: `${url}/TicketList@1.0`,
        method: 'POST',
        data: {
            data: {
                customer_uuid: uuid
            }
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return list;
}

export const feedbackArticle = async (customerUuid, articleUuid, rate, comments) => {
    let url = baseUrl.replace('{service}', 'articlefeedback');
    let data = {
        customer_uuid: customerUuid,
        article_uuid: articleUuid,
        article_rate: rate
    }

    if(comments){
        data['article_comments'] = comments;
    }
    let feedback = await axios({
        url: `${url}/ArticleFeedback@1.0`,
        method: 'POST',
        data: {
            data: data
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return feedback;
}

export const getTicket = async (body) => {
    let url = baseUrl.replace('{service}', 'ticketget');
    let ticket = await axios({
        url: `${url}/TicketGet@1.0`,
        method: 'POST',
        data: body
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return ticket;
}

export const sendComment = async (body) => {
    let url = baseUrl.replace('{service}', 'commentcreate');
    let comment = await axios({
        url: `${url}/CommentCreate@1.0`,
        //url: `http://localhost:8080/customer_service/service/CommentCreate@1.0`,
        method: 'POST',
        data: body
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return comment;
}

export const getComments = async (uuid) => {
    let url = baseUrl.replace('{service}', 'commentlist');
    let list = await axios({
        url: `${url}/CommentList@1.0`,
        method: 'POST',
        data: {
            data: {
                ticket_uuid: uuid
            }
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return list;
}

export const feedbackTicket = async (data) => {
    let url = baseUrl.replace('{service}', 'ticketfeedback');
    let feedback = await axios({
        url: `${url}/TicketFeedback@1.0`,
        method: 'POST',
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        return error.response;
    });

    return feedback;
}